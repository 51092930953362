<template>
    <div class="download-page">
        <div class="wrap">
            <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/downloadSoftware' }">所有软件</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/downloadSoftware' }">软件</el-breadcrumb-item>
                <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="box">
                <div class="info z-flex z-flex-between">
                    <img class="img" :src="img"/>
                    <div class="text">
                        <div class="title">{{title}}</div>
                        <div class="date">发布时间：<span>{{date}}</span></div>
                        <div class="free">{{free}}</div>
                    </div>
                    <el-button-group class="btn-group">
                        <el-button class="btn" v-if="category=='软件' && mac_down_url" @click="down(mac_down_url)"><i class="iconfont el-icon--left">&#xe881;</i>MAC版下载</el-button>
                        <el-button class="btn" v-if="category=='软件' && win_down_url" @click="down(win_down_url)"><i class="iconfont el-icon--left">&#xe882;</i>WIN版下载</el-button>
                        <el-button class="btn" v-if="category=='素材' && down_url" @click="down(down_url)">免费下载</el-button>
                    </el-button-group>
                </div>
                <div class="desc" v-html="desc"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    export default {
        components: {
            
        },
        computed: {
            ...mapState(['vuex_user'])
        },
        data() {
            return {
                mac_down_url:'',
                win_down_url:'',
                down_url:'',
                category:'',
                img:'https://dyyxclub-files.oss-cn-hangzhou.aliyuncs.com/images/xA4QMWNEt8gTvN4tlkq8hR3lijt4TnxyYFYGl5eK.jpeg',
                title:'Adobe Photoshop 2022+Win ACR14.0',
                date:'2021-10-21',
                free:true,
                desc:'Adobe Photoshop 2022（仅限WIN10及更高版本用户使用）Adobe Photoshop 2021（仅限MAC10.15及更高版本用户使用）',
            };
        },
        mounted() {
            this.$axios.api.downDetail({id: this.$route.params.id}, (res) => {
                if (res.code == 0) {
                    this.title = res.data.title;
                    this.category = res.data.category;
                    this.img = res.data.thumb;
                    this.date = res.data.published_at;
                    this.free = res.data.type;
                    this.desc = res.data.original_desc;
                    this.mac_down_url = res.data.mac_down_url;
                    this.win_down_url = res.data.win_down_url;
                    this.down_url = res.data.url;
                } else {
                    console.log(res.msg);
                }
            });
        },
        methods: {
            toUrl(url){
                this.$router.push({
                    path:url
                })
            },
            down(url){
                if (!this.vuex_user) {
                    //登录窗口
                    return this.$parent.$refs.navcom.login();
                }
                var a = document.createElement('a');
                a.href = url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        },
    };
</script>

<style lang="scss" scoped>
.breadcrumb{
    margin-top:30px;
    font-size: 16px;
    ::v-deep .el-breadcrumb__inner{
        color: #777;
        &.is-link:hover{
           color: $colorMain; 
        }
    }
}
.box{
    width:calc(100% - 60px);
    padding:20px 30px;
    background:#fff;
    border-radius: 4px;
    margin-top: 30px;
    .info{
        padding:20px 0;
        border-bottom: $borderDashed;
        align-items:flex-start;
        position: relative;
        .img{
            width: 130px;
            height: 130px;
            background: #eaedee;
            border-radius: 8px; 
        }
        .text{
            width:calc(100% - 160px);
            .title{
                font-weight: bold;
                color: #474747;
                font-size: 20px;
                max-width: 550px;
                @extend %textOverflow;
                margin-bottom: 10px;
            }
            .date{
                color: #ccc;
                font-size: 16px;
                span{
                    color:$colorMain;
                }
            }
            .free{
                font-size: 24px;
                color: #ff3738;
                font-weight: 700;
                line-height: 50px;
            }
        }
        .btn-group{
            position:absolute;
            right:0;
            bottom: 20px;
        }
    }
    .desc{
        padding:30px 0 0;
        ::v-deep p{
            width:100%!important;
            word-break: break-all;
        }
        ::v-deep img{
            max-width:100%!important;
        }
        ::v-deep video{
            width:100%!important;
        }
    }
}   
</style>
